import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { LensClient, production } from "@lens-protocol/client";

const lensClient = new LensClient({
  environment: production
});

export async function UserData(handle) {
  const profileByHandle = await lensClient.profile.fetch({
    forHandle: `lens/@${handle}`,
  })
  return profileByHandle;
}

export async function PublicationData(postId) {
  const publication = await lensClient.publication.fetch({
    forId: `${postId}`,
  });
  if (publication == null) {
    return null;
  } else {
    if (publication.__typename === 'Post') {
      return publication;
    } else if (publication.__typename === 'Mirror') {
      return publication.mirrorOn;
    } else if (publication.__typename === 'Comment') {
      return publication;
    } else if (publication.__typename === 'Quote') {
      return publication;
    }else {
      return null;
    }
  } 
}


export async function WalletData(wallet) {
  console.log('e')
/*   const { data } = await client.query({
    query: gql`
    query {
      defaultProfile(request: { ethereumAddress: "${wallet}"}) {
        handle
        name
        id
        stats {
          totalFollowers
          totalFollowing
          totalPublications
          totalCollects
        }
        followNftAddress
        ownedBy
      }
    }
  ` });
  return data?.defaultProfile?.handle; */
}
export async function domains(domain){
  console.log('e')
/*   let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://api.everyname.xyz/forward?domain=${domain}`,
    headers: { 
      'Accept': 'application/json', 
      'api-key': '9DDucXhNr8AyV63pBNzQstbY3mgpr9GCqN4BNqgG37JLNhvQpupNpyRSjsxB5'
    }
  };

  axios.request(config)
  .then((response) => {
    return(JSON.stringify(response.data));
  })
  .catch((error) => {
    console.log(error);
  }); */
}