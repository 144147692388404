import React, { useState, useEffect, } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

function ErrorPage() {
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    navigate(`/`)
  };

  return (
    <div>
      <h1>Lensvert</h1>
     { 
      <form onSubmit={handleSubmit}>
        <Button type="submit">{"Go back"}</Button>
      </form>
      }
    </div>
  );
}

export default ErrorPage;
