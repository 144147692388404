import React from 'react';

function Footer() {
  return (
    <footer>
      <a className="footer" href="https://lensvert.xyz/u/molens" target="_blank" rel="noopener noreferrer">Powered by molens</a>
    </footer>
  );
}

export default Footer;

