import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConvertPost } from './functions/ConvertPost.js';
import { ConvertUser } from './functions/ConvertUser.js';
import { domains } from './functions/graph.mjs';
import { PublicationData, UserData, WalletData } from './functions/graph.mjs';
import './style/HomePage.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function HomePage() {
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [submit, setSubmit] = useState('')
  const [input, setInput] = useState('');

  useEffect(() => {
    if (input === '') {
      navigate('/');
    }

    const fetchData = async () => {
      const query = input;
      const user = ConvertUser(query);
      const post = ConvertPost(query);
      console.log(`post ` + post);
      console.log(`user ` + user);
      if (query.length === 42 && query.includes('0x')) {
        console.log('in wallet');
        try {
          const walletInfo = await WalletData(query);

          if (walletInfo != null) {
            navigate(`/u/${walletInfo}`);
          } else {
            navigate('/error');
          }
        } catch (error) {
          navigate('/error');
        }
      }else if(query.includes('-')){
        if (post !== undefined) {
          try {
            const publicationData = await PublicationData(post);
            console.log(publicationData)
            if (publicationData != null) {
              navigate(`/p/${post}`);
            } else {
              navigate('/error');
            }
          } catch {
            navigate('/error');
          }
        }
      } else if (user !== '') {
        try {
          const userData = await UserData(user);

          if (userData != null) {
            navigate(`/u/${user}`);
          } else {
              navigate('/error');
          }
        } catch (error) {
          navigate('/error');
        }
      }
    };

    fetchData();
  }, [submit, navigate]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value.replace(/ /g, "");
    setInput(inputValue);
    setIsButtonDisabled(inputValue === '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmit(true)
  };

  return (
    <div className="container">
      <h1 className="title">Lensvert</h1>

      <form className="form" onSubmit={handleSubmit}>
        <TextField inputProps={{ style: { textAlign: 'center' } }} autoFocus placeholder="Enter or escape" onChange={handleInputChange} />
        <Button disabled={isButtonDisabled} type="submit" color="primary" size="medium" variant="contained" style={{ marginTop: '10px' }}>
          Submit
        </Button>
      </form>
    </div>
  );
}

export default HomePage;
