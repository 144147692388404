import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserData } from './functions/graph.mjs';
import { useNavigate } from 'react-router-dom';
import './style/UserPage.css';
import * as icon from "./icons";

function UserPage() {
  const { handle } = useParams();
  const [profile, setProfile] = useState(null);
  const [dispatcher, setDispatcher] = useState(null);
  const [sponsored, setSponsored] = useState(null);
  const [recomended, setRecomended] = useState(null);
  const [app, setApp] = useState(null);
  const [apps, setApps] = useState(null);
  const navigate = useNavigate();
  const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
  const appsList = [ 
    {
      name: 'Hey',
      link: 'https://hey.xyz/u/'
    },
    {
      name: 'Orna',
      link: 'https://orna.art/u/danisnearby/'
    },    
    {
      name: 'Kaira',
      link: 'https://kaira.network/'
    },        
    {
      name: 'Orb',
      link: 'https://orb.ac/@'
    },
    {
      name: 'Butterfly',
      link: 'https://buttrfly.app/profile/'
    },    
    {
      name: 'Bloomers.tv',
      link: 'https://bloomers.tv/'
    },
  ];

  function handleCopy(id) {
    var copyText = document.getElementById('appButton' + id).href;
    var textArea = document.createElement('textarea');
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    alert("Link has been copied successfully.");
  }

  function handleShare(id) {
    let data = { files: [], text: ``, url: `https://lensvert.xyz/p/${id}`, title: `Lensvert ${id}` };
    if (navigator.share) {
      navigator.share(data)
        .then(() => {
          console.log('Successfully shared!');
        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
    } else {
      console.log('Web Share API not supported');
    }
  }

  useEffect(() => {
    const indexToRemove = appsList.findIndex(appsList => appsList.name === app);
    if (indexToRemove !== -1) {
      appsList.splice(indexToRemove, 1);
    }     
    setApps(appsList)
  }, [app]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await UserData(handle);
        if (data == null){
          console.log('error handler')
          navigate('/error');
        }
        if(!isMobile){
          setRecomended("https://hey.xyz/u/")
          setApp("Hey")
        }else{
          setRecomended("https://orb.ac/post/")
          setApp("Orb")
        }
        setProfile(data);
        setDispatcher(data.sponsor)
        if(data.sponsor !== null){
          setSponsored(data.sponsor.toString())
        }else{
          setSponsored("Dispatcher not enabled")
        }
        console.log(data)
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    }

    fetchData();
  }, [handle, navigate, isMobile]);

  return (
    <div>
      <h1>User Profile: {handle}.lens</h1>
      <div className="appsPanel">
        <div className="Collumn">
          <div className="Button">
            {app && ( <a className="appButton" id="appButton2" href={apps[0].link + handle} target="_blank" rel="noopener noreferrer" > {apps[0].name} </a> )}
            {isMobile ? ( <p></p>) : ( <button className="buttonIcon" onClick={() => handleCopy(2)}><img alt="" src={icon.collect}/></button> )}       
          </div>
        </div>

        <div className="Collumn">
          <div className="Button">
            {app && ( <a className="appButton" id="appButton3" href={apps[1].link + handle} target="_blank" rel="noopener noreferrer" > {apps[1].name} </a> )}
            {isMobile ? ( <p></p>) : ( <button className="buttonIcon" onClick={() => handleCopy(3)}><img alt="" src={icon.collect}/></button> )}       
          </div>
        </div>

        <div className="Collumn">
          <div id="recomended" className="Button">
            {recomended &&  app && ( <a className="appButton" id="appButton1" href={recomended + handle} target="_blank" rel="noopener noreferrer" > {app} </a> )}
            {isMobile ? ( <p></p>) : ( <button className="buttonIcon" onClick={() => handleCopy(1)}><img alt="" src={icon.collect}/></button> )}       
          </div>
          <div className="Button">
            {app && ( <a className="appButton" id="appButton4" href={apps[2].link + handle} target="_blank" rel="noopener noreferrer" > {apps[2].name} </a> )}
            {isMobile ? ( <p></p>) : ( <button className="buttonIcon" onClick={() => handleCopy(4)}><img alt="" src={icon.collect}/></button> )}       
          </div>
        </div>

        <div className="Collumn">
          <div className="Button">
            {app && ( <a className="appButton" id="appButton5" href={apps[3].link + handle} target="_blank" rel="noopener noreferrer" > {apps[3].name} </a> )}
            {isMobile ? ( <p></p>) : ( <button className="buttonIcon" onClick={() => handleCopy(5)}><img alt="" src={icon.collect}/></button> )}       
          </div>
        </div>

        <div className="Collumn">
          <div className="Button">
            {profile && app && ( <a className="appButton" id="appButton6" href={apps[4].link + profile.id} target="_blank" rel="noopener noreferrer" > {apps[4].name} </a> )}
            {isMobile ? ( <p></p>) : ( <button className="buttonIcon" onClick={() => handleCopy(6)}><img alt="" src={icon.collect}/></button> )}       
          </div>
        </div>
      </div>
      <div className="profilePanel">
        <div className="info">
          <a>
            {profile && (<img alt=""  className="AvatarImage" src={profile.metadata.picture.optimized.uri}  alt="" />)}
          </a>
        </div>
        <div className="stats">
          <div className="statsbox">
            <img alt="" className="icon" src={icon.followers}/>
            {profile && <p>{profile.stats.followers}</p>}
          </div>
          <div className="statsbox">
            <img alt="" className="icon" src={icon.following}/>
            {profile && <p>{profile.stats.following}</p>}
          </div>
          <div className="statsbox">
            <img alt="" className="icon" src={icon.comment}/>
            {profile && <p>{profile.stats.publications}</p>}
          </div>
          <div className="statsbox">
            <img alt="" className="icon" src={icon.collect}/>
            {profile && <p>{profile.stats.collects}</p>}
          </div>
        </div>
      </div>
      <div className="dataPanel">
        <div className="data">
          {profile && (<p><a href={`https://polygonscan.com/address/{profile.ownedBy.address}`} target="_blank">Owner: {profile.ownedBy.address}</a></p>)}
          {profile && (<p><a href={`https://polygonscan.com/token/${profile.followNftAddress.address}`} target="_blank">Follow NFT: {profile.followNftAddress.address}</a></p>)}
          {dispatcher !== null ? (
            <p>Gasless: {sponsored}</p>
            ) : (
            <p>Dispatcher not enabled</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserPage;
