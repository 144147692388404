import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PublicationData } from './functions/graph.mjs';
import { useNavigate } from 'react-router-dom';
import './style/PostPage.css';
import * as icon from "./icons";

function PostPage() {
    const { publication } = useParams();
    const [post, setPost] = useState(null);
    const [media, setMedia] = useState(null);
    const [recomended, setRecomended] = useState(null);
    const [app, setApp] = useState(null);
    const [apps, setApps] = useState(null);
    const navigate = useNavigate();
    const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
    const appsList = [
        { name: 'Hey', link: 'https://Hey.xyz/p/' },
        { name: 'Orna', link: 'https://orna.art/c/' },
        { name: 'Kaira', link: 'https://kaira.network/posts/' },
        { name: 'Orb', link: 'https://orb.ac/post/' },
        { name: 'Butterfly', link: 'https://buttrfly.app/post/' },
        { name: 'Lenspeer', link: 'https://lenspeer.com/posts/' }
    ];

    function handleCopy(id) {
        var copyText = document.getElementById('appButton' + id).href;
        var textArea = document.createElement('textarea');
        textArea.value = copyText;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert("Link has been copied successfully.");
    }

    function handleShare(id) {
        let data = { files: [], text: ``, url: `https://lensvert.xyz/p/${id}`, title: `Lensvert ${id}` };
        if (navigator.share) {
            navigator.share(data)
                .then(() => {
                    console.log('Successfully shared!');
                })
                .catch((error) => {
                    console.error('Error sharing:', error);
                });
        } else {
            console.log('Web Share API not supported');
        }
    }

    useEffect(() => {
        const indexToRemove = appsList.findIndex(appsList => appsList.name === app);
        if (indexToRemove !== -1) {
            appsList.splice(indexToRemove, 1);
        }
        setApps(appsList);
    }, [app]);

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await PublicationData(publication);
                console.log(data);

                // Check if data and its metadata are available
                if (!data || !data.metadata) {
                    handleDefaultRecommendation();
                    return;
                }

                setPost(data);
                if (data.by) {
                    console.log("By property exists:", data.by);
                }

                const type = data.metadata?.__typename;
                const contentLength = data.metadata?.content?.length || 0;
                const isImage = type?.includes("Image");
                const isVideo = type?.includes("Video");
                const isAudio = type?.includes("Audio");

                // If type and media are defined
                if (type && data.metadata.media) {
                    const url = getUrl(data, type);
                    setMediaContent(type, url);

                    // Recommendations based on content type and length
                    if (isImage) {
                        handleRecommendations(contentLength, 50, "Hey", "Orb");
                    } else if (isVideo) {
                        handleRecommendations(contentLength, 100, "Lenstube", "Orb");
                    } else if (isAudio) {
                        handleRecommendations(contentLength, 50, "beats", "Orb");
                    } else {
                        // For other content types
                        handleDefaultRecommendation();
                    }
                } else {
                    // Type is defined but media isn't
                    handleDefaultRecommendation();
                }
            } catch (error) {
                console.log(post);
                console.log(`error: ${error}`);
                navigate('/error');
            }
        }

        // Function to handle media content
        function setMediaContent(type, url) {
            if (url) {
                const src = url.includes("ipfs://") ? url.replace('ipfs://', 'https://') + '.ipfs.dweb.link' : url;

                if (type.includes("Image")) {
                    setMedia(`<img alt="" className="media" src="${src}">`);
                } else if (type.includes("Video")) {
                    setMedia(`<video className="media" controls src="${src}"/>`);
                } else if (type.includes("Audio")) {
                    setMedia(`<audio className="media" controls src="${src}"/>`);
                }
            } else {
                setMedia('');
            }
        }

        // Function to handle content URL
        function getUrl(data, type) {
            let url;
            if (type.includes("Image")) {
                url = data.metadata.asset?.image?.optimized?.uri;
            } else if (type.includes("Video") || type.includes("Audio")) {
                url = data.metadata.asset?.optimized?.uri;
            }
            return url;
        }

        // Function to handle recommendations
        function handleRecommendations(contentLength, threshold, nonMobileApp, mobileApp) {
            if (contentLength < threshold) {
                if (!isMobile) {
                    setRecomended("https://hey.xyz/p/");
                    setApp(nonMobileApp);
                } else {
                    setRecomended("https://orb.ac/post/");
                    setApp(mobileApp);
                }
            } else {
                if (!isMobile) {
                    setRecomended("https://hey.xyz/p/");
                    setApp(nonMobileApp);
                } else {
                    setRecomended("https://orb.ac/post/");
                    setApp(mobileApp);
                }
            }
        }

        // Function to handle default recommendations
        function handleDefaultRecommendation() {
            if (!isMobile) {
                setRecomended("https://hey.xyz/p/");
                setApp("Hey");
            } else {
                setRecomended("https://orb.ac/post/");
                setApp("Orb");
            }
        }

        fetchData();
    }, [publication, navigate, isMobile]);

    return (
        <div>
            <h1>Publication: {publication}</h1>
            <div className="appsPanel">
                {/* Render the buttons for apps */}
                {apps && apps.map((appData, index) => (
                    <div className="Collumn" key={index}>
                        <div className="Button">
                            {post && <a className="appButton" id={`appButton${index + 1}`} href={appData.link + post.id} target="_blank" rel="noopener noreferrer">{appData.name}</a>}
                            {!isMobile && <button className="buttonIcon" onClick={() => handleCopy(index + 1)}><img alt="" src={icon.collect} /></button>}
                        </div>
                    </div>
                ))}
            </div>
            <div className="publication">
                {post && post.by && (
                    <div className="by-info">
                        <a className="handle" href={`https://lensvert.xyz/u/${post.by.handle.localName}`}>{post.by.handle.localName}</a>
                    </div>
                )}
                <div className="content">
                    {post && <p>{post.metadata.content}</p>}
                    {post && <p>{post.by.metadata.content}</p>}

                </div>
                <div className="stats">
                    <div className="statsbox">
                        <img alt="" className="icon" src={icon.like} />
                        {post && <p>{post.stats.upvotes}</p>}
                    </div>
                    <div className="statsbox">
                        <img alt="" className="icon" src={icon.mirror} />
                        {post && <p>{post.stats.mirrors}</p>}
                    </div>
                    <div className="statsbox">
                        <img alt="" className="icon" src={icon.comment} />
                        {post && <p>{post.stats.comments}</p>}
                    </div>
                    <div className="statsbox">
                        <img alt="" className="icon" src={icon.collect} />
                        {post && <p>{post.stats.collects}</p>}
                    </div>
                </div>
            </div>
            {!isMobile && (
                <div className="circle">
                    <div className="shareButton">
                        {post && <button className="shareIcon" onClick={() => handleShare(post.id)}> <img alt="" src={icon.mirror} alt="Share" /> </button>}
                    </div>
                </div>
            )}
        </div>
    );
}

export default PostPage;