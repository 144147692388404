export function ConvertPost(link) {

    if (link.startsWith("0x")){
      return link
    }else if (!link.includes('_')){
      const parameterStartIndex = link.indexOf('0x');
      const domain = link.substring(0, parameterStartIndex);
      return link.split(domain)[1];
    }else{
      const url = link
      const lastSlashIndex = url.lastIndexOf('/');
      const urlEnd = url.slice(lastSlashIndex + 1);
    
      // Split the remaining portion by underscores
      const parts = urlEnd.split('_');
    
      // Convert the first two parts to hexadecimal
      const part1Hex = hex(parseInt(parts[0]))
      const part2Hex = hex(parseInt(parts[1]))
    
      // Combine the hexadecimal values to create the lensterId
      const lensterId = `0x${part1Hex}-0x${part2Hex}`;
    
      // Return the lensterId
      return lensterId;
    }
  }

function hex(link){
    const h = link.toString(16);
    return h.length % 2 ? '0' + h : h;
}
