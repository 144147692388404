import Footer from './components/footer.jsx'
import HomePage from './components/HomePage.jsx';
import UserPage from './components/UserPage.jsx';
import PostPage from './components/PostPage.jsx';
import ErrorPage from './components/ErrorPage.jsx';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App">
        <header className="App-header">
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route index element={<HomePage />} />
                <Route path="u/:handle" element={<UserPage />} />
                <Route path="p/:publication" element={<PostPage />} />
                <Route path="error" element={<ErrorPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </header>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
